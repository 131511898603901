// PONGO TODOS LOS QUE SE REPITEN EN LOS COMPONENTES

@use 'generalColors';

// ---------------------------------- MAIN STYLES

.principal-color {
    color: generalColors.$skyBlue;
}

.secundary-color {
    color: generalColors.$orange;
}

.third-color {
    color: generalColors.$blue;

}

.bgColor {
    background-color: generalColors.$blackBackground;
}

.menu-txt {
    font-weight: 400;
    font-size: 16px;
}

.subtitle-one {
    font-weight: 700;
    font-size: 1.1rem;
    line-height: 2;
    margin: 0;
}

.subtitle-two {
    font-weight: 700;
    font-size: 2rem;
    margin-bottom: 35px;
}

.subtitle-tree {
    font-weight: 700;
    font-size: 1.1rem;
}

.text-p {
    font-weight: 400;
    font-size: 0.7rem;
}

.btn-h3 {
    font-weight: 500;
    font-size: 10pt;
}


// ---------------------------------- TITLE
.title {
    margin: 5% 0 5%;
    padding-top: 5%;

    .title_container {
        width: 100%;
        margin: auto;

        .title_iso {
            width: 2.1rem;
            margin-right: 15px;
        }
    }
}

// ---------------------------------- FLEX

.d-flex-col {
    display: flex;
    flex-direction: column;
}

.d-flex-col-just-center {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.d-flex-col-align-center {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.d-flex-col-just-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.d-flex-a-center {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.d-flex-j-center {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.d-flex-row-wrap-j-center {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}

.d-flex-row-wrap-j-center-a-center {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.d-flex-col-wrap {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

// ---------------------------------- UNDER CONSTRUCTION
.underConstruction {
    width: 100%;
    min-height: 100vh;
    padding: 100px;

    .constructionLogo {
        width: 150px;
        margin: 0 auto 25px;
    }

    img {
        margin-bottom: 50px;
    }

}