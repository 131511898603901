.footer {
    width: 100%;
    height: 100%;
    padding: 30px 0;
    background-color: #14140c;
    position: relative;
    z-index: 5;

    .footer_logo {
        width: 100px;
        margin-top: 25px;

    }

    .footer_menu {
        margin: 30px auto;
        width: 30em;
        justify-content: space-around;

        li {
            list-style: none;

            a {
                text-decoration: none;
                color: #ffff;
            }

            a:hover {
                filter: invert(63%) sepia(83%) saturate(2512%) hue-rotate(359deg) brightness(109%) contrast(108%);

            }
        }
    }

    .footer_sm {
        margin-top: 20px;

        a {

            width: 25px;
            margin: 12px;
        }

        a:hover {
            filter: invert(63%) sepia(83%) saturate(2512%) hue-rotate(359deg) brightness(109%) contrast(108%);
        }
    }
}

@include screen_smart {
    .footer {
        width: 100%;
        padding: 20px;

        .footer_menu {
            width: 100%;
        }
    }
}