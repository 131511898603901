.VideoComponent {
    width: 100%;
    margin: auto;



    .videoComponent_container {
        margin: 30px auto;


        .videoComponent_container-video {
            width: 100%;
            height: auto;
            position: relative;
        }
    }

}