.personalizedAdvice-bg {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;


    .personalizedAdvice {
        padding-bottom: 8%;

        .title {
            margin: 0% 0 5%;
        }

        .personalizedAdvice_subtitle {
            text-align: center;
            width: 70%;
            margin: auto;
            min-width: 300px;

            .text-p {
                width: 60%;
                margin: 0 auto 40px;
            }
        }

        .personalizedItems {
            display: flex;
            justify-content: center;

            .item {
                .btn-h3 {
                    margin-bottom: 0;
                }

                img {
                    width: 30px;
                    filter: invert(55%) sepia(100%) saturate(893%) hue-rotate(359deg) brightness(106%) contrast(105%); //orange
                    margin-right: 5px;
                }
            }

            .personalizedItems_section1 {
                max-height: 156px;
                min-width: 330px;
                margin: auto;
                gap: 7px;
            }

            @media (max-width: 1000px) {
                .personalizedItems_section1 {
                    max-height: 100%;
                    justify-content: center;

                }

            }
        }
    }

    .my-bg {
        position: absolute;
        width: 100%;
        height: 100%;
        bottom: 0px;
        left: -15%;
        opacity: .08;


        img {
            width: 100%;
            transform: scale(2);

        }
    }
}