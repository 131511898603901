.characteristic {
    width: 60%;
    margin: auto;

    .characteristic-subtitle {
        text-align: center;
        width: 70%;
        margin: auto;
        min-width: 300px;
    }

    .characteristic_body {

        .characteristic_body-ul {
            flex: 2;
            list-style: none;
            min-width: 350px;

            .characteristic_body-ul-li {
                justify-content: center;

                margin: 24px 0 0;

                .characteristic_body-ul-li-icon {
                    margin-right: 17px;
                    filter: invert(55%) sepia(100%) saturate(893%) hue-rotate(359deg) brightness(106%) contrast(105%); //orange
                    transition: filter 1s;

                    img {
                        width: 55px;
                    }
                }

                .characteristic_body-ul:hover .characteristic_body-ul-li-icon {
                    filter: invert(44%) sepia(84%) saturate(1490%) hue-rotate(192deg) brightness(100%) contrast(99%); //skyblue
                }

                .characteristic_body-ul-li-txt {
                    width: 70%;

                    .subtitle-tree {
                        margin-bottom: .3rem;
                    }

                    .text-p {
                        margin-bottom: 0;
                    }
                }

            }
        }

        .characteristic_body-img {
            flex: 3;
            min-width: 350px;
            padding: 35px;

            img {
                width: 100%;
            }
        }
    }
}