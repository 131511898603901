.logoDesign {
    width: 60%;
    margin: auto;

    .logoDesign_section1 {
        .logoDesign_section1-container {
            flex-direction: row-reverse;

            .logoDesign_section1-txt {
                flex: 3;
                max-width: 500px;
                min-width: 270px;

            }

            .logoDesign_section1-img {
                min-width: 250px;
                height: 250px;
                margin: 0 20px;
                flex: 1;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    overflow: hidden;
                    border-radius: 15px;
                }
            }
        }
    }

    .logoDesign_section2 {

        .logoDesign_section2-container {
            flex-direction: row !important;

            .logoDesign_section1-txt {
                flex: 3;
                max-width: 500px;
                min-width: 270px;
                text-align: right;
            }

            @include screen_desk {

                .logoDesign_section1-txt {
                    text-align: center;
                }
            }

            .logoDesign_section1-img {
                min-width: 250px;
                height: 250px;
                margin: 0 20px;
                flex: 1;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    overflow: hidden;
                    border-radius: 15px;
                }
            }
        }
    }

    @include screen_desk {

        .logoDesign_section1-container {
            text-align: center !important;
        }
    }
}