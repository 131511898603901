.topArrow {
    width: 30px;
    position: fixed;
    bottom: 15px;
    right: 5%;
    filter: invert(44%) sepia(84%) saturate(1490%) hue-rotate(192deg) brightness(100%) contrast(99%);
    transition: filter .5s;
    z-index: 50;

}

.topArrow:hover {
    filter: invert(63%) sepia(83%) saturate(2512%) hue-rotate(359deg) brightness(109%) contrast(108%);
}