@use '../../Sass/generalColors';

.header {
    position: relative;
    width: 90%;
    height: 100%;
    margin: auto;
    position: relative;
    padding: 15px;

    .burger {

        display: none;

    }


    @include screen_desk {
        .navBar {
            display: none;
        }

        .burger {
            display: block;
            position: absolute;
            z-index: 6;
            top: 33%;
            left: 0px;

            .hamburger {
                position: absolute;
                top: -15px;
                padding: 0px !important;
            }

            .hamburger_menu {
                display: block;
                background-color: #ffffff28;
                backdrop-filter: blur(7px);
                padding: 15px 30px;
                border-radius: 10px;
                position: absolute;
                top: 45px;

                li {
                    list-style: none;
                    width: 75px;
                    position: relative;

                    a {
                        color: #ffffff;
                        text-decoration: none;

                        .hamburger-inner {
                            background-color: #ffffff;

                            &,
                            &::before,
                            &::after {
                                width: #ffff;

                            }
                        }

                    }

                    #dropdown-basic-button {
                        background-color: transparent;
                        border: none;
                        font-weight: 400 !important;
                        font-size: 16px !important;
                        padding: 0 !important;

                    }

                    #dropdown-basic-button::after {
                        content: none;
                    }

                    #dropdown-basic-button:hover,
                    a:hover {
                        color: generalColors.$orange;
                    }

                    .dropdown-menu {
                        border: none;
                        background-color: rgb(0 0 0 / 89%);
                        border: 1px solid generalColors.$orange;

                        transform: translate3d(91px, 4px, 0px) !important;
                    }

                    .dropdown-item:hover {
                        color: generalColors.$orange;
                        background-color: transparent;
                    }
                }

                a:hover {
                    color: generalColors.$orange;
                }
            }
        }
    }

    .navBar {
        margin: 0 5px;
        position: absolute;
        bottom: 21px;

        li {
            list-style: none;
            margin: 0 7px !important;
            padding: 0;

            a {
                color: #ffffff;
                text-decoration: none;
            }

            #dropdown-basic-button {
                background-color: transparent;
                border: none;
                font-weight: 400 !important;
                font-size: 16px !important;
                padding: 5px !important;


            }

            #dropdown-basic-button::after {
                content: none;
            }

            #dropdown-basic-button:hover,
            a:hover {
                color: generalColors.$orange;
            }

            .dropdown-menu {
                border: none;
                background-color: #ffffff28;
                backdrop-filter: blur(7px);

                transform: translate3d(-2.7778px, 27.634921px, 0px) !important;



            }

            .dropdown-item:hover {
                color: generalColors.$orange;
                background-color: transparent;
            }

        }
    }


    .logo {
        width: 100%;
        padding: 15px;
        top: 15px;
        text-align: center;

        img {
            width: 100px;
        }
    }

}