@use '../../Sass/generalColors';

.contact {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;

    .contact_body {
        width: 60%;
        margin: auto;
        padding-bottom: 80px;


        .contact_container {
            justify-content: space-between;


            .contact_txt {
                min-width: 390px;
                flex: 1;

                .text-p {
                    width: 70%;
                }
            }


            .contact_input {
                flex: 1;
                min-width: 290px;
                z-index: 8;


                .inputBox {
                    background-color: #ffffff11;
                    //   background-color: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.2))

                    border: 1px solid generalColors.$skyBlue;

                    // border: var(--bs-border-width) solid var(--bs-border-color);
                    transition: border-color .25s ease-in-out, box-shadow .35s ease-in-out;
                }

                .btn {
                    width: 100%;
                }

                .btn-primary {
                    background-color: generalColors.$skyBlue;
                    transition: all .5s ease;
                }

                .btn-primary:hover {
                    background-color: generalColors.$orange;
                    border: 1px solid generalColors.$orange;
                }
            }

            .inputBox::placeholder {
                color: #ffffff;
                opacity: 0.8;
                font-weight: 400;
                font-size: 0.8rem;
            }

            .form-control:focus {
                border-color: generalColors.$orange !important;
                box-shadow: 0 0 0 .25rem #c07e0322;
                color: #ffffff;
            }

            .form-control,
            .form-control:target {
                color: #ffffff;
            }
        }
    }

    .contact-bg {
        min-width: 100%;
        max-width: 1300px;
        position: absolute;
        top: -3%;
        left: -5%;
        opacity: .5;
    }
}

.mi-popup {
    width: 50%;
}


@include screen_desk {
    .contact {

        .contact_container {
            align-items: center;
            justify-content: center;

            .contact_txt {
                align-items: center;

                .text-p {
                    width: 100%;
                    margin-bottom: 25px;
                    text-align: center;
                }
            }
        }

        .contact-bg {
            top: -6%;
            height: 110%;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }

}

@include screen_smart {
    .contact {
        align-items: center;

        .contact_body {
            width: 90%;
            margin: auto;

            .contact_container {
                align-items: center;
                justify-content: center;

                .contact_txt {
                    align-items: center;

                    .text-p {
                        width: 100%;
                        margin-bottom: 25px;
                        text-align: center;
                    }
                }

            }
        }
    }
}