@use '../../Sass/generalColors';

.whyChooseUs-bg {

    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.2)),
        url('../../../public/Assets/WhyChoose-bg.png');

    background-position: left;
    background-size: 66% 100%;
    background-repeat: no-repeat;


}

.whyChooseUs {
    width: 60%;
    margin: auto;

    .whyChoose_container {
        display: flex;
        flex-direction: column;

        .whyChoose_container-sectionOne {
            .subtitle-two {
                margin-bottom: 20px;
            }

            .sectionOne_subtitle {
                width: 30%;
            }
        }

        .whyChoose_container-sectionTwo {

            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            align-items: center;

            .whyChoose_container-sectionTwo-info {
                flex: 1;
                min-width: 400px;


                .sectionTwo-info-txt {
                    width: 70%;
                    margin: 30px 0;

                    .text-p {
                        margin-bottom: 0;
                    }
                }

                .sectionTwo-info-txt::before {
                    content: "";
                    display: flex;
                    width: 10px;
                    height: 100px;
                    background-color: generalColors.$orange;
                    margin-right: 10px;
                }

            }

            .whyChoose_container-sectionTwo-img {
                flex: 1;
                min-width: 300px;

                img {
                    width: 100%;
                }
            }
        }

        @include screen_desk {
            .whyChoose_container-sectionOne {
                display: flex;
                flex-direction: column;
                align-items: center;

                .sectionOne_subtitle {
                    width: 100%;
                    text-align: center;
                }
            }


            .whyChoose_container-sectionTwo {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: center;

                .whyChoose_container-sectionTwo-info {
                    .sectionTwo-info-txt {
                        margin: 20px auto;

                    }
                }

                .whyChoose_container-sectionTwo-img {
                    margin-top: 25px;
                }

                .sectionOne_subtitle {
                    width: 100%;
                    text-align: center;
                }

            }
        }
    }

}

@include screen_smart {
    .whyChooseUs {
        min-width: 80%;
    }
}