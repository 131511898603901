@use '../../Sass/generalColors';

.service-bg {
    width: 100%;
    height: 100%;
    position: relative;

    .service {
        width: 70%;
        margin: auto;
        position: relative;
        z-index: 10;

        .service_body {
            position: relative;

            .service_body-subtitle {
                text-align: center;
                margin-top: 15px;
            }

            .serviceLinks {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                gap: 10px;

                .serviceLinks_card {
                    text-align: center;
                    width: 220px;
                    background-color: generalColors.$blue;
                    border-radius: 15px;
                    border: 3px solid generalColors.$skyBlue;
                    margin: 15px;
                    padding: 0 15px;
                    transition: all 0.5s ease;
                    text-decoration: none;
                    color: #ffff;

                    h2 {
                        width: 100%;
                        background-color: generalColors.$skyBlue;
                        border-radius: 3px;
                        padding: 5px 8px;
                        margin-bottom: 25px;
                        transition: all 0.7s ease;

                    }

                    .serviceIcon {
                        width: 50%;
                        margin: 25px;
                        filter: invert(44%) sepia(84%) saturate(1490%) hue-rotate(192deg) brightness(100%) contrast(99%);
                        transition: all .4s ease;
                        transition: transform .5s ease;

                    }
                }

                .serviceLinks_card:hover {
                    border: 3px solid generalColors.$orange;

                    h2 {
                        background-color: generalColors.$orange;
                    }

                    .serviceIcon {
                        filter: invert(55%) sepia(100%) saturate(893%) hue-rotate(359deg) brightness(106%) contrast(105%);
                        transform: scale(1.05);
                    }
                }
            }

            @media (max-width: 1280px) {
                .serviceLinks {
                    width: 90%;
                    margin: auto;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    justify-content: center;
                }
            }

            .serviceCircles {
                width: 30%;
                position: absolute;
                top: 0;
                left: -35%;
            }
        }
    }

    @include screen_smart {
        .service {
            width: 90%;

            .service_body {

                .serviceLinks {
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);

                    .serviceLinks_card {
                        width: 100%;
                        margin: 0;
                    }

                }

            }
        }
    }

}


// grid-template-columns: repeat(2, 1fr); /* 2 columnas en pantallas medianas */

.my-service-bg {
    height: 108%;
    position: absolute;
    top: -3%;
    right: 0;
    opacity: .5;


    img {
        height: 100%;
        transform: scale(1.1);
        transform: scaleX(-1);
    }
}