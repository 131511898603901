.aboutUs-bg {
    width: 100%;
    height: 100%;
    position: relative;

    .AboutUs {
        width: 60%;
        min-width: 590px;
        margin: auto;
        position: relative;
        z-index: 5;
        padding-bottom: 5%;

        .title {
            margin-bottom: 0;
        }

        .AboutUs_body {
            width: 100%;
            margin-top: 3%;
            position: relative;

            .AboutUs_body-subtitle {
                width: 27%;
                position: absolute;
                bottom: 65%;
                left: 0;
            }

            .AboutUs_body-p {
                position: absolute;
                width: 27%;
                right: 0;
                bottom: 32%;
                //margin-bottom: 15px;
            }

            .AboutUs_body-img {
                margin-top: 15%;
                position: relative;

                img {
                    width: 100%;
                }
            }

        }

    }

    @include screen_smart {
        .AboutUs {
            min-width: 80%;


            .AboutUs_body {
                display: flex;
                flex-direction: column;
                justify-content: center;

                .AboutUs_body-subtitle {
                    width: 100%;
                    position: relative;
                    text-align: center;
                    margin-bottom: 5px;
                }

                .AboutUs_body-p {
                    width: 100%;
                    position: relative;
                    text-align: center;
                }

                .AboutUs_body-img {
                    margin-top: 0;

                    img {
                        width: 100%;
                    }
                }
            }
        }
    }


    .my-bg {
        position: absolute;
        width: 100%;
        height: 100%;
        opacity: 0.4;
        top: 0;
        right: 0;
        overflow: hidden;



        img {
            transform: scale(2);
            opacity: .22;
        }
    }
}