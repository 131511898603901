.bodyOne-bg {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;


    .bodyOne {
        width: 90%;
        margin: auto;
        gap: 25px;
        position: relative;
        z-index: 80;
        padding: 30px;

        .sectionOne {
            flex: 2;
            max-width: 450px;
        }

        .sectionTwo {
            flex: 1;

            img {
                width: 80%;
                min-width: 300px;
                border-radius: 15px;
            }
        }
    }

    .my-bg {
        position: absolute;
        width: 100%;
        height: 100%;
        bottom: 0px;
        left: -15%;
        opacity: .3;


        img {
            width: 100%;
            transform: scale(2);

        }
    }
}

@include screen_desk {
    .sectionOne {
        text-align: center;
    }

    .sectionTwo {
        text-align: center;
    }
}