.project-bg {

    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;

    .projects {
        width: 60%;
        height: 100%;
        margin: auto;
        position: relative;
        z-index: 80;

        .projects-subtitle {
            text-align: center;
        }

        .slider-container {
            width: 100%;
            max-width: 600px;
            margin: 0 auto;
        }

        .slick-slide img {
            width: 100%;
            height: 200px;
            object-fit: cover;
            object-position: center;

        }

    }

    .my-bg {
        position: absolute;
        width: 100%;
        height: 100%;
        bottom: -5px;
        left: -15%;
        opacity: .18;


        img {
            width: 100%;
            height: 100%;
            transform: scale(1.6);

        }
    }
}

@include screen_smart {
    .projects {
        width: 100%;

        .slick-slide img {
            width: 100%;
            height: 200px;
            object-position: center;
        }
    }


}