.paralax {
    width: 100%;
    min-height: 20%;
    padding: 30px 0 0 20px;
    position: relative;
    margin: 15px auto 25px;
    overflow: hidden;

    .paralax_title {
        width: 90%;
        font-size: 97px;
        line-height: 97px;
        justify-content: flex-start;
        z-index: 5;
        margin: auto;

        .third-line {
            color: #ffffff;
        }
    }

    .container {
        width: 90%;
        padding: 3px;
        max-width: 100%;


        .slogan {
            font-weight: 700;
            font-size: 40px;
            color: rgb(255, 255, 255);
            margin-top: 40px;
        }
    }

    .bg-images {
        width: 100%;
        height: 100%;
        position: absolute;
        color: #ffffff;

        .square-bg {
            width: 28vw;
            position: absolute;
            right: 14vw;
            bottom: 1vw;
            opacity: 0.6;
            animation: rotate-fade 2s ease-in-out;

        }

        .arrow-bg {
            width: 40vw;
            position: absolute;
            left: -8vw;
            top: -45px;
            opacity: 0.5;
        }

        .circle-bg {
            width: 14vw;
            position: absolute;
            right: -1vw;
            top: -1vw;
            opacity: 0.7;
        }
    }
}

@include screen_desk {

    .paralax {

        .paralax_title {
            font-size: 8vw;
            line-height: 8vw;
        }

        .container {
            .slogan {
                font-weight: 700;
                font-size: 4vw;
                color: rgb(255, 255, 255);
                margin-top: 45px;
            }
        }
    }
}

// .container {
//     max-width: 1175px;
//     width: 90%;
//     padding: 3px;
//     margin: auto;

//     .slogan {
//         font-weight: 700;
//         font-size: 40px;
//         color: rgb(255, 255, 255);
//         margin-top: 40px;
//     }
// }





@include screen_smart {
    .paralax {
        position: relative;
        min-height: 20px !important;
    }

    .slogan {
        margin-top: 10px;
    }



    .square-bg {
        width: 300px !important;
        bottom: -20px !important;
    }

    .arrow-bg {
        width: 250px !important;
        left: -50px !important;
    }

    .circle-bg {
        width: 120px !important;
    }

}



@keyframes rotate-fade {
    0% {
        opacity: 0;
        transform: rotate(45deg);
    }

    100% {
        opacity: .8;
        transform: rotate(0);
    }
}